<style lang="less" scoped>
	img{
	  width: 100%;
	  height: 100%;
	}
.active {
  color: #0090f0;
}
.footerLink {
  width: 1200px;
  margin: 100px auto;
  display: flex;
  justify-content: space-between;
  .footerLeft {
    width: 250px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 30px 20px;
    box-sizing: border-box;
    height: 300px;
    div {
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #eee;
      cursor: pointer;
    }
    div:last-child {
      border: none;
    }
  }
  .content {
    width: 900px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 40px;
    box-sizing: border-box;
  }
}
</style>
<template>
  <div class="footerLink">
    <div class="footerLeft">
      <div
        @click="changeTabs(index)"
        v-for="(item, index) in list"
        :key="index"
        :class="{ active: active == index }"
      >
        {{ item.title }}
      </div>
    </div>
    <div class="content">
      <div v-if="list[active]">
        <div v-html="list[active].content"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { footerLink } from "../assets/js/index.js";
export default {
  data() {
    return {
      active: -1,
      list: [],
    };
  },
  mounted() {
    this.active = this.$route.query.active;
    this.init();
  },
  methods: {
    changeTabs(i) {
      this.active = i;
    },
    init() {
      footerLink({
        siteId: window.localStorage.getItem("siteId"),
        // siteId: 1,
      }).then((res) => {
        // console.log(res);
        this.list = res.data;
      });
    },
  },
};
</script>
